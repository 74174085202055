import { RouteReuseStrategy } from "@angular/router/";
import { ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";

export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  allowRetrieveCache = ["campaigns", "search"];

  constructor() {
    addEventListener("resize", () => {
      this.clearStoredRouteHandles();
    });
  }

  clearStoredRouteHandles() {
    this.storedRouteHandles = new Map<string, DetachedRouteHandle>();
  }

  shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return before.routeConfig === curr.routeConfig;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRouteHandles.get(this.getPathTitle(route)) as DetachedRouteHandle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPathTitle(route);
    if (this.allowRetrieveCache.indexOf(path) >= 0) {
      return this.storedRouteHandles.has(path);
    }

    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPathTitle(route);
    return this.allowRetrieveCache.indexOf(path) >= 0;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getPathTitle(route), detachedTree);
  }

  private getPathTitle(route: ActivatedRouteSnapshot): any {
    return route.routeConfig !== null && route.routeConfig.path !== null ? route.routeConfig?.title : "";
  }
}
