import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AlertAndNotificationModule } from "alert-and-notification";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
const environment = new ConfigurationService().getConfig();
import { AuthModule } from "@auth0/auth0-angular";
import { environment as env } from "../environments/environment";
import { RouteReuseStrategy } from "@angular/router";
import { CacheRouteReuseStrategy } from "./core/cache-route-reuse-strategy";
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    AlertAndNotificationModule.forRoot(environment.alertAndNotificationService),
    AuthModule.forRoot({
      ...env.auth0,
    }),
  ],
  providers: [
    HttpClient,
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
