import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { User } from "./core/models/domain/user";
import { PlatoAlertService } from "./core/svc/plato-alert-svc/plato-alert.service";
import { IconService } from "./core/icon.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "plato-ui";
  users: User[] = [];

  @ViewChild("alertTemplate", { read: ViewContainerRef, static: true }) platoAlertContainer: ViewContainerRef;
  constructor(private platoAlertService: PlatoAlertService, private iconService: IconService) {}

  ngOnInit() {
    this.platoAlertService.initComponent(this.platoAlertContainer);
    this.iconService.registerIcons();
  }
}
