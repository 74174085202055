import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./core/components/login/login.component";
import { AdminGuard } from "./core/admin.guard";
import { HomeGuard } from "./core/home.guard";
import { LoginGuard } from "./core/login.guard";
// import { HomeElementsGuard } from './core/home-elements.guard';
import { HomeElementsAgencyGuard } from "./core/home-elements-CA.guard";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    canLoad: [LoginGuard],
    canActivate: [LoginGuard],
  },
  {
    path: "",
    canLoad: [HomeGuard],
    canActivate: [HomeGuard],
    children: [
      {
        path: "admin",
        canLoad: [AdminGuard],
        canActivate: [AdminGuard],
        loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "",
        loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [AdminGuard, HomeGuard, LoginGuard],
})
export class AppRoutingModule {}
