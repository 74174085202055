import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizationService } from "./svc/authorization.service";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
@Injectable({
  providedIn: "root",
})
export class HomeGuard {
  constructor(private router: Router, private authorization: AuthorizationService) {}

  private _canActivate(allowed: boolean) {
    if (allowed) {
      return true;
    }
    this.router.navigate(["/login"]);
    return false;
  }

  canActivate() {
    return this.allowed().pipe(map((allowed) => this._canActivate(allowed)));
  }

  canLoad() {
    return this.allowed();
  }

  private allowed() {
    return this.authorization.loggedIn$;
  }
}
