import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizationService } from "./svc/authorization.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoginGuard {
  constructor(private router: Router, private authorization: AuthorizationService) {}

  private _canActivate(allowed: boolean) {
    if (allowed) return true;
    this.router.navigate(["/"]);
    return false;
  }

  canActivate() {
    return this.allowed().pipe(map((allowed) => this._canActivate(allowed)));
  }

  canLoad() {
    return this.allowed();
  }

  private allowed() {
    return this.authorization.loggedIn$.pipe(map((loggedIn) => !loggedIn));
  }
}
