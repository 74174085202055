import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizationService } from "./svc/authorization.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AdminGuard {
  constructor(private authorization: AuthorizationService, private router: Router) {}

  private _canActivate(allowed: boolean) {
    if (allowed) return allowed;
    this.router.navigate(["/"]);
    return allowed;
  }

  canActivate() {
    return this.allowed().pipe(map((allowed) => this._canActivate(allowed)));
  }

  canLoad() {
    return this.allowed();
  }

  allowed() {
    return this.authorization.authorities$.pipe(map((roles) => roles.includes("ROLE_ADMIN")));
  }
}
